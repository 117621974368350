import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'

/**
 * @description: judge a promotion is need group
 * abt cartStoreFreegift store promotion abt switch pageId=1466292809
 * abt 开启  
 *   全店活动 仅聚合附属品
 *   非全店活动正常聚合
 * abt 关闭
 *   全店活动 仅置顶附属品, 不展示活动头
 *   非全店活动正常聚合
 * 因此逻辑合并, 可不进行abt判断
 * @param {*} promotion promotion detial
 * @return {*}
 */
function isNeedGroupPromotion(promotion = {}) {
  if(
    (
      promotion.type_id == 14 && (promotion.goods?.type != 3)
    ) || 
    PROMOTION_CONFIG.cartNeedGroupPromotionTypeIds.includes(Number(promotion.type_id)) // match old group config
  ) {
    if([2, 28].includes(Number(promotion.type_id))) {
      // return false
      return promotion.all_store_flag != 1 // 非全店活动才进行聚合
    } else return true
  } else return false
}

/**
 * 处理促销/obm店铺组头
 * wiki.pageId=998790240
 * @param handleStore 处理店铺数据 出参：店铺组与促销组同级
 * @param { Object } ctx context
 * @param { Boolean } ctx.cartStoreFreegift store promotion abt switch pageId=1466292809
 * @return [{
 *    group_type:'promotion/store',
 *   id,
 *   list:[{product}]
 * }]
 */
export function handlerCartsPromotionGroup({ goods = [], integrityPromotionInfo = {}, handleStore = false } = {}, ctx = {}){
  let promotionGroup = []
  // 需要分组的促销活动购物车
  let sheinClubOrFullGiftCard = null
  let addBuyAllCard = null
  let othersCard = null
  let group = []
  goods.forEach(item => {
    item.isDisabled = item.isMallSwitchGoods || item.isSaleAttrSwitchGoods
    // 新人专享价商品
    if (item.status == 2 && item.promotion_status == 12) {
      item.isNewcomerItem = true
      item.isDisabled = true
    }
    
    let isPromotionGroup = false
    item.product.product_promotion_info?.forEach(promotion => {
      const id = promotion.promotion_id
      if(!integrityPromotionInfo[id]) return
      const promotionInfo = integrityPromotionInfo[id] || {}

      // 部分满减 以及分组活动参与分组
      if (isNeedGroupPromotion(promotionInfo, {
        cartStoreFreegift: ctx?.cartStoreFreegift
      })) {
        // 全场满减（特殊的活动，单品分组，全场不分组）

        item.promotionGroupId = id
        // if([2, 28].includes(+promotion.type_id)){ // 部分满赠、买赠有赠品列表或已选赠品时聚合展示
        //   let hasGiftList = promotionInfo.rules?.find(rule => rule.list?.length)
        //   const isPicked = goods.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '1' && p.promotion_id == id))
        //   if(hasGiftList || isPicked) {
        //     isPromotionGroup = true
        //   }
        // } else {
        //   isPromotionGroup = true
        // }
        isPromotionGroup = true
      }
      if([22, 4].includes(promotion.type_id)){
        if(item.fullPromotionGroup){
          item.fullPromotionGroup[promotion.type_id] = id
        } else {
          item.fullPromotionGroup = {}
          item.fullPromotionGroup[promotion.type_id] = id
        }
      }
      if(promotion.type_id == 4 && !sheinClubOrFullGiftCard){
        sheinClubOrFullGiftCard = JSON.parse(JSON.stringify(integrityPromotionInfo[id]))
        sheinClubOrFullGiftCard.list = [
          [],
          [],
          [],
        ]
      }

      if(promotion.type_id == 22 && !addBuyAllCard){
        addBuyAllCard = JSON.parse(JSON.stringify(integrityPromotionInfo[id]))
        addBuyAllCard.list = [
          [],
          [],
          []
        ]
      }

      // 追加特殊商品tag
      if (promotion.type_id == 22) item.isAddBuyAllItem = promotion.promotion_product_type == '2'
      if (promotion.type_id == 13) item.isAddBuyItem = promotion.promotion_product_type == '2'
      if (promotion.type_id == 2) item.isBuyGiftItem = promotion.promotion_product_type == '1'
      if (promotion.type_id == 4) {
        const bool = promotion.promotion_product_type == '1'
        if ([8, 14].includes(promotion.promotion_logo_type)) {
          item.isSheinClubGiftItem = bool
        } else {
          item.isFullGiftItem = bool
        }
      }
      if (promotion.type_id == 28) item.isPartFullGiftItem = promotion.promotion_product_type == '1'

      item.isAddItem = Boolean(item.isAddBuyAllItem || item.isAddBuyItem)
      item.isGiftItem = Boolean(item.isBuyGiftItem || item.isSheinClubGiftItem || item.isFullGiftItem || item.isPartFullGiftItem)
      // 特殊商品禁用 checkbox
      item.isDisabled = item.isDisabled || item.isAddItem || item.isGiftItem || item.isMallSwitchGoods || item.isSaleAttrSwitchGoods
    })

    if (item.isSheinClubGiftItem || item.isFullGiftItem) { // 全场满赠
      sheinClubOrFullGiftCard.list = [
        [],
        [],
        [],
      ]
      // sheinClubOrFullGiftCard.list.push(item)
      if (item.isSheinClubGiftItem) {
        sheinClubOrFullGiftCard.list[0].unshift(item)
      } else {
        if (item.isMallSwitchGoods) {
          sheinClubOrFullGiftCard.list[1].push(item)
        } else if (item.isSaleAttrSwitchGoods) {
          sheinClubOrFullGiftCard.list[2].push(item)
        } else {
          sheinClubOrFullGiftCard.list[0].push(item)
        }
      }
      if(handleStore ){
        handleFullPromotion(sheinClubOrFullGiftCard, group)
      }
    } else if (item.isAddBuyAllItem) { // 全场加价购
      addBuyAllCard.list = [
        [],
        [],
        [],
      ]
      // addBuyAllCard.list.push(item)
      if (item.isMallSwitchGoods) {
        addBuyAllCard.list[1].push(item)
      } else if (item.isSaleAttrSwitchGoods) {
        addBuyAllCard.list[2].push(item)
      } else {
        addBuyAllCard.list[0].push(item)
      }
      if(handleStore ){
        handleFullPromotion(addBuyAllCard, group)
      }
    } else if (isPromotionGroup ) {
      let item_promotion_info = integrityPromotionInfo[item.promotionGroupId]
      let targetPromotion = promotionGroup.find(cardItem => cardItem.promotion_id == item.promotionGroupId)
      if(targetPromotion) {
        /**
         * 礼品置于卡片顶
         * 2: 买赠;
         * 13: 加价购;
         */
        if (item.isAddBuyItem || item.isBuyGiftItem || item.isPartFullGiftItem) {
          targetPromotion.list[0].unshift(item)
        } else {
          // targetPromotion.list.push(item)
          if (item.isMallSwitchGoods) {
            targetPromotion.list[1].push(item)
          } else if (item.isSaleAttrSwitchGoods) {
            targetPromotion.list[2].push(item)
          } else {
            targetPromotion.list[0].push(item)
          }
        }
      } else {
        item_promotion_info.list = [
          [],
          [],
          []
        ]
        if (item.isMallSwitchGoods) {
          item_promotion_info.list[1].push(item)
        } else if (item.isSaleAttrSwitchGoods) {
          item_promotion_info.list[2].push(item)
        } else {
          item_promotion_info.list[0].push(item)
        }
        item_promotion_info.group_type = 'promotion'
        // item_promotion_info.list.push(item)
        promotionGroup.push(item_promotion_info)
        group.push(item_promotion_info)
      }
    } else { // 普通商品
      if(handleStore){
        let store_code = item.store_code
        let storeCart =  group.find(groupItem => groupItem.store_code === store_code)
        if(storeCart) {
          storeCart.store_list_origin.push(item)
          // storeCart.list.push(item)
          if (item.isMallSwitchGoods) {
            storeCart.list[1].push(item)
          } else if (item.isSaleAttrSwitchGoods) {
            storeCart.list[2].push(item)
          } else {
            storeCart.list[0].push(item)
          }
          storeCart.preferred_seller_store = item.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store
        } else {
          let storeItem = {
            group_type: 'store',
            store_code: item.store_code,
            storeCode: item.store_code,
            store_title: item.store_title,
            store_logo: item.store_logo,
            store_type: item.store_type,
            preferred_seller_store: item.preferred_seller_store,
            list: [
              [],
              [],
              []
            ],
            store_list_origin: []
          }
          storeItem.store_list_origin.push(item)
          if (item.isMallSwitchGoods) {
            storeItem.list[1].push(item)
          } else if (item.isSaleAttrSwitchGoods) {
            storeItem.list[2].push(item)
          } else {
            storeItem.list[0].push(item)
          }
          // storeItem.list.push(item)
          group.push(storeItem)
        }
      } else {
        if(!othersCard){
          othersCard = { id: 'common', list: [
            [],
            [],
            [],
          ] }
          promotionGroup.push(othersCard) // OBM和Three店铺都关闭情况下, 聚合活动与普通商品组顺序不做特殊处理
        }
        if (item.isMallSwitchGoods) {
          othersCard.list[1].push(item)
        } else if (item.isSaleAttrSwitchGoods) {
          othersCard.list[2].push(item)
        } else {
          if((item.isPartFullGiftItem || item.isBuyGiftItem) && ctx?.cartStoreFreegift) {
            othersCard.list[0].unshift(item)
          } else {
            othersCard.list[0].push(item)
          }
        }
        // othersCard.list.push(item)
      }
    }
  })
  if(addBuyAllCard){
    promotionGroup.unshift(addBuyAllCard)
  }
  if(sheinClubOrFullGiftCard){
    promotionGroup.unshift(sheinClubOrFullGiftCard)
  }
  // othersCard && promotionGroup.push(othersCard)
  return handleStore ? group : promotionGroup
}

function handleFullPromotion(fullCard, group) {
  fullCard.list.forEach(item => {
    let storeCart =  group.find(groupItem => groupItem.store_code === item.store_code)
    if(storeCart) {
      storeCart.store_list_origin.unshift(item)
      storeCart.list.unshift(item)
      storeCart.preferred_seller_store = item.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store
    } else { // 店铺下只有附属品的极端场景
      let storeItem = {
        group_type: 'store',
        store_code: item.store_code,
        storeCode: item.store_code,
        store_title: item.store_title,
        store_logo: item.store_logo,
        store_type: item.store_type,
        preferred_seller_store: item.preferred_seller_store,
        list: [],
        store_list_origin: []
      }
      storeItem.store_list_origin.push(item)
      storeItem.list.push(item)
      group.push(storeItem)
    }
  })
}

export function handleCouponGoodsList(cartsData, goodsList) {
  const newArr = []
  if (!goodsList?.length || !cartsData?.length) return newArr
  cartsData.map(item => {
    if (goodsList.some(val => val?.cart_id == item?.id)) {
      newArr.push(item)
    }
  })
  return newArr
}

/**
 * @description: 获取商品展示时的聚合类型, 判断单个商品的聚合类型, 同时向商品中添加特殊标识
 * https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1167730128
 * @param {*} item 商品行item
 * @param {*} integrityPromotionInfo 促销详情
 * @param { Object } ctx 额外的上下文字段
 * @param {*} ctx.fullGiftCard 4,全场满赠促销组, 当前阶段默认不传
 * @param {*} ctx.addBuyAllCard 22,全场加价购促销组, 当前阶段默认不传
 * @param { Boolean } ctx.cartStoreFreegift 
 * @return { 
*   groupType: { storeLevel: boolean, innerStore: boolean },
*   specialGroup: { fullGiftCard, addBuyAllCard }
* } storeLevel: 跨店铺活动聚合, innerStore: 内部内部活动聚合, 其他情况默认在店铺下展示
*   fullGiftCard: 全场满赠(4), addBuyAllCard: 全场加价购(22)
*/
export function getGroupType(item, integrityPromotionInfo, ctx = {}) {
  let fullGiftCard = ctx?.fullGiftCard || null
  let addBuyAllCard = ctx?.addBuyAllCard || null
  item.isDisabled = item.isMallSwitchGoods || item.isSaleAttrSwitchGoods
  item.isBlindBoxFlag = item.blind_box_flag == '1' // 盲盒商品

  // 新人专享价商品
  if (item.status == 2 && item.promotion_status == 12) {
    item.isNewcomerItem = true
    item.isDisabled = true
  }

  let groupType = {
    storeLevel: false,
    innerStore: false,
  }
  if (item?.product?.product_promotion_info?.length) {
    item.product.product_promotion_info.forEach(promotion => {
      const id = promotion.promotion_id
      if (!integrityPromotionInfo[id]) return
      const promotionInfo = integrityPromotionInfo[id] || {}

      // 部分满减 以及分组活动参与分组
      if (isNeedGroupPromotion(promotionInfo, {
        cartStoreFreegift: ctx?.cartStoreFreegift
      })) {
        item.promotionGroupId = id
        if (promotionInfo.subject == 1) groupType.storeLevel = true
        else if (promotionInfo.subject == 2) groupType.innerStore = true
      }
      if ([22, 4].includes(promotion.type_id)) {
        if (item.fullPromotionGroup) {
          item.fullPromotionGroup[promotion.type_id] = id
        } else {
          item.fullPromotionGroup = {}
          item.fullPromotionGroup[promotion.type_id] = id
        }
      }
      // 特殊分组
      if (promotion.type_id == 4 && !fullGiftCard) {
        fullGiftCard = JSON.parse(JSON.stringify(integrityPromotionInfo[id]))
        fullGiftCard.list = []
      }

      if (promotion.type_id == 22 && !addBuyAllCard) {
        addBuyAllCard = JSON.parse(JSON.stringify(integrityPromotionInfo[id]))
        addBuyAllCard.list = []
      }

      // 追加特殊商品tag
      if (promotion.type_id == 22) item.isAddBuyAllItem = promotion.promotion_product_type == '2'
      if (promotion.type_id == 13) item.isAddBuyItem = promotion.promotion_product_type == '2'
      if (promotion.type_id == 2) item.isBuyGiftItem = promotion.promotion_product_type == '1'
      if (promotion.type_id == 4) {
        const bool = promotion.promotion_product_type == '1'
        if ([8, 14].includes(promotion.promotion_logo_type)) {
          item.isSheinClubGiftItem = bool
        } else {
          item.isFullGiftItem = bool
        }
      }
      if (promotion.type_id == 28) item.isPartFullGiftItem = promotion.promotion_product_type == '1'

      item.isAddItem = Boolean(item.isAddBuyAllItem || item.isAddBuyItem)
      item.isGiftItem = Boolean(item.isBuyGiftItem || item.isSheinClubGiftItem || item.isFullGiftItem || item.isPartFullGiftItem)
      // 特殊商品禁用 checkbox
      item.isDisabled = item.isDisabled || item.isAddItem || item.isGiftItem
    })
  }
  return {
    groupType,
    specialGroup: {
      fullGiftCard, // 4 全场满赠
      addBuyAllCard // 22 全场加价购
    }
  }
}

/**
 * @description: find store group by store code if not found then create
 * @param {*} mallCart mall group item
 * @param {*} isSheinStore if is shein store
 * @param {*} cartItem curent processing cart item
 * @param { Object } ctx context infomation object
 * @param { Object } ctx.promotionDetials all promotion detials for all store promotion  pageId=1466292809
 * @param { Object } ctx.cartStoreFreegift all store promotion abt switch pageId=1466292809
 * @return {*}
 */
export function getStoreItemByStoreCode(mallCart, isSheinStore, cartItem, ctx = {}) {
  let store_code = isSheinStore ? 'shein' : cartItem.store_code
  let storeCart = mallCart.mall_list.find(mallItem => mallItem.store_code === store_code)
  if (!storeCart) {
    storeCart = {
      store_code: store_code,
      storeCode: cartItem.store_code,
      store_title: isSheinStore ? 'shein' : cartItem.store_title,
      store_logo: isSheinStore ? '' : cartItem.store_logo,
      store_type: cartItem.store_type,
      preferred_seller_store: cartItem.preferred_seller_store,
      store_list: [],
      store_list_origin: []
    }
    if(ctx?.cartStoreFreegift && !isSheinStore) {
      const freeGiftPromotions = Object.values(ctx?.promotionDetials || {}).filter(promo => promo.all_store_flag == 1 && [2, 28].includes(promo.type_id) && promo.store_id == store_code)
      // const freeGiftPromotions = Object.values(ctx?.promotionDetials || {}).filter(promo => promo.all_store_flag == 1 && [2, 28].includes(promo.type_id) && store_code == '8153511997') // debugger code
      freeGiftPromotions.forEach(promo => {
        if(typeof promo == 'object') promo.list = []
      })
      storeCart.innerUnGroupPromotions = freeGiftPromotions
    }
    mallCart.mall_list.push(storeCart)
  }
  return storeCart
}

// 30 天最低价 A ----- suggested_sale_price
// 原价 B -------retailPrice
// 促销活动后的价格 C ---- unit_price_before_coupon

/**
 * 获取de合规价格类型 pageId=1384306511
 * @param {Object} item
 * @returns {Number}
 */
export function getSuggestedPriceType({ suggestedSalePrice, retailPrice, unitPriceBeforeCoupon }) {
  if (suggestedSalePrice > 0) {
    if (suggestedSalePrice < retailPrice) {
      if (retailPrice > unitPriceBeforeCoupon) {
        return 1
      } else if (retailPrice === unitPriceBeforeCoupon) {
        return 2
      }
    } else if (suggestedSalePrice === retailPrice) {
      if (retailPrice > unitPriceBeforeCoupon) {
        return 3
      } else if (retailPrice === unitPriceBeforeCoupon) {
        return 4
      }
    }
    return 5
  } else if (suggestedSalePrice === 0) {
    return 6
  }
  return null
}

export function getSuggestedPriceTypeByCartItem(item) {
  return getSuggestedPriceType({
    suggestedSalePrice: +item.product?.suggested_sale_price?.amount || 0,
    retailPrice: +item.product?.retailPrice?.amount || 0,
    unitPriceBeforeCoupon: +item.unit_price_before_coupon?.amount || 0
  })
}
export function getSuggestedPriceTypeByAppendageItem(item) {
  return getSuggestedPriceType({
    suggestedSalePrice: +item.suggested_sale_price?.amount || 0,
    retailPrice: +item.retail_price?.amount || 0,
    unitPriceBeforeCoupon: +item.sale_price?.amount || 0
  })
}
